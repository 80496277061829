.recieve_payment_container{
    margin-top: 7em;
}
@media (min-width: 700px) {
    .recieve_payment_container{
        display: flex;
        align-items: flex-start;
        width: 100%;
        margin: 7em 1em 1em;
    }
}
@media (min-width: 1020px) {
    .recieve_payment_container{
        justify-content: center;
        margin: 7em auto auto;
    }
}
.recieve_text_btn_container{
    width: 85%;
    margin: auto;
}
@media (min-width: 1020px) {
    .recieve_text_btn_container{
        margin-left: 2.5em;
    }
}

.payment_h2{
    font-size: 2rem;
    line-height: 44px;
    font-family: "Sora", sans-serif;
    font-weight: 600;
    margin-bottom: 1em;
}
@media (min-width: 1020px) {
    .payment_h2{
        width: 65%;
        font-size: 2.4rem;
    }
}

.recieve_paragraph{
    color: #6c6f70;
    font-family: "Sora", sans-serif;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 1.5em;
}
@media (min-width: 1020px) {
    .recieve_paragraph{
        width: 80%;
    }
}
.get_btn{
    margin-top: 3em;
    margin-bottom: 2em;
    border-radius: 6px;
}
.money_recievd_image{
    width: 90%;
    /* margin-left: 1em; */
    margin: 4em auto auto .5em;
    /* margin-top: 4em; */
}
@media (min-width: 700px) {
    .money_recievd_image{
        width: 50%;
    }
}
@media (min-width: 1020px) {
    .money_recievd_image{
        width: 30%;
        margin: auto 2.5em auto auto;
    }
}

/* ACCEPT PAYMENT STYLES START */
.accept_payment_h{
    font-size: 2rem;
    line-height: 44px;
    font-family: "Sora", sans-serif;
    font-weight: 600;
    color: #050505;
    text-align: center;
    width: 90%;
    margin: 2em auto auto;
}
@media (min-width: 900px) {
    .accept_payment_h{
        width: 68%;
    }
}
@media (min-width: 1020px) {
    .accept_payment_h{
        width: 50%;
    }
}
.accept_payment_p{
    color: #6c6f70;
    font-family: "Sora", sans-serif;
    font-size: 1rem;
    line-height: 30px;
    font-weight: 400;
    margin: 1em auto 1em;
    text-align: center;
    width: 95%;
}
@media (min-width: 900px) {
    .accept_payment_p{
        width: 85%;
        font-size: 1.1rem;
    }
}
@media (min-width: 1020px) {
    .accept_payment_p{
        width: 50%;
    }
}

.accpet_payment_img{
    margin-top: 1em;
    margin-bottom: 1.5em;
    margin-left: 1em;
}
@media (min-width: 900px) {
    .accpet_payment_img{
        margin-left: 4.5em;
    }
}
@media (min-width: 1020px) {
    .accpet_payment_img{
        margin-left: 4em;
    }
}
.money_recievd_image1{
    width: 95%;
    height: auto;
}

/* OUR CUSTOMERS STORIES STYLE START */
.our_customer_h2{
    font-family: "Sora", sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 34px;
    background: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 2.5em;
    margin-bottom: 2.2em;
    text-align: center;
}

.our_customer_p{
    font-size: .95rem;
    line-height: 2;
    color: #2d3132;
    font-family: "Sora", sans-serif;
    font-weight: 600;
    text-align: center;
    width: 95%;
}
@media (min-width: 900px) {
    .our_customer_p{
        width: 70%;
        margin: auto;
    }
}
.quote{
    background: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.ceo_name_image{
    margin-top: 3.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.ceo_name{
    font-family: "Sora", sans-serif;
    color: #2d3132;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    padding-bottom: .3em;
}

.sabi_ceo{
    font-family: "Sora", sans-serif;
    color: #6c6f70;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
    /* margin-top: -5px; */
}

.ceo_img{
    width: 25%;
    height: 25%;
    border-radius: 50%;
}
@media (min-width: 600px) {
   .ceo_img{
    width: 100px;
    height: 100px;
    /* margin-bottom: 2em; */
   }
}
