.user_main_container{
    margin: 1.2em;
}


.user_top{
    display: flex;
    flex-wrap: wrap;
    width: 95%;
}
@media (min-width: 600px) {
    .user_top{
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
    }
}

.user_send_recieve_money_t{
    margin-bottom: 3em;
}
.user_h{
    font-family: "Sora", sans-serif;
    color: #050505;
    line-height: 46px;
    font-weight: 500;
    font-size: 2.1rem;
    width: 300px;
    margin-bottom: .8em;
}
@media (min-width: 1200px) {
    .user_h{
        font-size: 2.8rem;
        width: 400px;
        line-height: 52px;
        font-weight: 600;
    }
}
.user_p{
    font-family: "Sora", sans-serif;
    color: #6c6f70;
    font-size: 16px;
    width: 350px;
    line-height: 28px;
    margin-bottom: 1.2em;
}
@media (min-width: 1200px) {
    .user_p{
        font-size: 1.1rem;
        width: 530px;
    }
}

.app_btn_div{
    display: flex;
    flex-wrap: wrap;
    gap: 1.1em;
    width: 95%;
}

.user_app_img{
    width: 22rem;
}
@media (min-width:852px) {
    .user_app_img{
        width: 48%;
    }
}