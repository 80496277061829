/* .top_info{
    display: flex;
    flex-direction: column;
    justify-content: center;
} */
.about_btn{
    color: #4C4C63;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    background: #EEECF8;
    border-radius: 3px;
    padding: 9px 14px;
    margin-left: 8em;
}
@media (min-width: 600px) {
    .about_btn{
        padding: 1.2em;
        margin-left: 15.5em;
    }
}
@media (min-width: 1020px) {
    .about_btn{
        padding: 1em;
        display: flex;
        justify-content: center;
        width: 15%;
        margin: auto;
    }
}

.about_top_header{
    font-size: 48px;
    margin-top: 20px;
    color: #2C264A;
    font-weight: 800;
    line-height: 1.2;
    letter-spacing: -0.02em;
    text-align: center;
    margin: 1.3em auto 1.4em;
}
@media (min-width: 900px) {
    .about_top_header{
        width: 90%;
    }
}
@media (min-width: 1020px) {
    .about_top_header{
        width: 50%;
    }
}
@media (min-width: 600px) {
    .about_us_top_image{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
@media (min-width: 1020px) {
    .about_us_top_image{
        flex-direction: row;
        margin: 1.5em;
    }
}

.about_image1{
    width: 100%;
    padding: 1em 1em ;
}
@media (min-width: 600px) {
    .about_image1{
        width: 90%;
        margin: auto;
    }
}

@media (min-width: 1020px) {
    .img1{
        width: 64%;
    }
}
@media (min-width: 900px) {
    .help_african_m{
        width: 80%;
    }
}
@media (min-width: 1020px) {
    .help_african_m{
        display: flex;
        gap: 3em;
    }
}

.help_african_h2{
    color: #2c264a;
    font-weight: 800;
    font-size: 28px;
    font-family: "Manrope", sans-serif;
    text-align: left;
    width: 95%;
    margin: .7em;
}
@media (min-width: 600px) {
    .help_african_h2{
        width: 75%;
    }
}
@media (min-width: 900px) {
    .help_african_h2{
        margin-left: 2em;
    }
}
@media (min-width: 1020px) {
    .help_african_h2{
        font-size: 2.2rem;
    }
}

.help_african{
    margin: .7em;
    color: #4c4c63;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}
@media (min-width: 600px) {
    .help_african{
        width: 90%;
        font-size: 1rem;
    }
}

@media (min-width: 900px) {
    .help_african{
        margin-left: 3em;
    }
}

.about_money{
    margin: .8em;
}
@media (min-width: 900px) {
    .about_money{
        margin-left: 3em;
    }
}
@media (min-width: 1020px) {
    .about_money{
        display: flex;
        margin-top: 4em;
        align-items: center;
        margin-left: 4.5em;
    }
}
.ones{
    font-family: "Manrope", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 70px;
    margin-bottom: -5px;
    display: inline-block;
    color: #23BE6E;
}

.send_money, .receive_money,.collect_payment_h{
    color: #181b31;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 15px;
    font-family: "Manrope", sans-serif;
}

.send_money_p{
    color: #4c4c63;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

.collect_payment_p, .receive_money_p{
    color: #4c4c63;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

@media (min-width: 600px) {
    .send_money_p,.collect_payment_p, .receive_money_p{
        font-size: 1rem;
        width: 80%;
    }
}