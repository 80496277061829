.payment_boxes_div{
    display: flex;
    width: 95%;
    margin: auto;
    flex-wrap: wrap;
    align-items: center;
    gap: 2em;
}
@media (min-width: 1020px) {
    .payment_boxes_div{
        flex-wrap: nowrap;
        margin-top: 2em;
    }
}
.box_div_content{
    background: #e9f5fe;
    padding-left: 26px;
    padding-top: 45px;
    padding-bottom: 40px;
    border-radius: 15px;
    position: relative;
    width: 95%;
    margin: auto;
}
.box_div_content:hover{
    cursor: pointer;
    transform: translateY(-.3rem);
    transition: all .2s ease-in;
}
@media (min-width: 700px) {
    .box_div_content{
        width: 80%;
    }
}

.box{
    display: flex;
    justify-content: space-between;
}
.info_box1{
    width: 58px;
    height: 58px;
    background: #d7eaff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.box_img{
    position: relative;
    bottom: 2em;
}
.info_box_header{
    color: #050505;
    font-family: "Sora", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
}

.info_box_p{
    color: #5e6282;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    padding-top: 1em;
    margin-bottom: 35px;
}

.box_div_content_process{
    background-color: rgb(242, 240, 255);
    padding-left: 26px;
    padding-top: 45px;
    padding-bottom: 40px;
    border-radius: 15px;
    position: relative;
    width: 95%;
    margin: auto;
}
.box_div_content_process:hover{
    cursor: pointer;
    transform: translateY(-.3rem);
    transition: all .2s ease-in;
}
@media (min-width: 700px) {
    .box_div_content_process{
        width: 80%;
        padding-left: 26px;
        padding-top: 65px;
        padding-bottom: 40px;
        border-radius: 15px;
        position: relative;
        margin: auto;
    }
}
.info_box3{
    width: 58px;
    height: 58px;
    background-color: rgb(218, 213, 251);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.handle{
    background-color: rgb(233, 255, 237) !important;
}