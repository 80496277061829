header{
   background-color: #fff;
   margin-bottom: 8em;
}
.navbar{
    padding: .8em;
    /* position: fixed; */
    width: 100%;
    position: fixed;
    background-color: #fff;
    z-index: 100;
    top: 0;
}
.container_fluid{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (min-width: 1020px) {
    .container_fluid{
        justify-content: space-around;
        padding: 1em;
    }
}

.img{
    /* height: 45px; */
    width: 150px;
    margin-left: .8em;
}

/* DESKTOP  */
._nav_list{
    display: none;
}
@media (min-width: 700px) {
    ._nav_list{
        display: block;
        
    }
    ._nav_list> ul{
        display: flex;
        align-items: center;
        gap: 2em;
    }
    ._nav_list> ul > li{
        display: flex;
        gap: .8em;
        align-items: center;
        font-size: .8rem;
        cursor: pointer;
    }
    ._nav_list> ul > li> .link{
        display: flex;
        gap: .8em;
        align-items: center;
    }
    ._nav_list> ul > li> .link:hover{
        color: #20c997;
    }


}
@media (min-width: 1020px) {
    ._nav_list> ul > li{
        font-size: 1.2rem;
        font-weight: 600;
    }
}

.drop_why, .drop_learn{
    position: relative;
    display: inline-block;
}
.drop_why_content{
    opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -300px;
  margin-top: 3em;
  /* margin-right: 2em; */
  transform: translate(0, 10px);
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 680px;
  height: 200px;
  border-radius: 5px;
}
.drop_why_content:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  }
.drop_why:hover .drop_why_content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
  .drop_why > li{
    font-size: 1.1rem;
    font-weight: 600;
  }
  .drop{
    display: flex;
    gap: .8em;
    align-items: center;
  }
  .drop:hover{
    fill: #20c997;
    color: #20c997;
  }

  /* first drop down content styles  */
  .drop_why_content{
    padding: 1.2em;
  }
  .drop_section{
    display: flex;
    align-items: center;
    gap: 3em;
    padding-top: 1.2em;
  }

  .header_drop{
    font-weight: 700;
    font-size: 1.1rem;
    margin-bottom: 1em;
  }
  .drop_list{
    margin-bottom: .9em;
    cursor: pointer;
  }
  .drop_list:hover{
    color: #20c997;
  }
  
  /* first drop down content styles  end*/
  /* LEARN DROP DOWN STARTS */
  .drop_learn_content{
    opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -50px;
  margin-top: 3em;
  /* margin-right: 2em; */
  transform: translate(0, 10px);
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 180px;
  border-radius: 5px;
}
.drop_learn_content:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  }
.drop_learn:hover .drop_learn_content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
  .drop_learn > li{
    font-size: 1.1rem;
    font-weight: 600;
  }
  .learn_list{
    margin-bottom: .8em;
    cursor: pointer;
  }
  .learn_list:hover{
    color: #20c997;
  }
  /* ENDS */

.authentication_link{
    display: none;
}
@media (min-width: 1020px) {
    .authentication_link{
        display: flex;
        align-items: center;
        gap: 1.5em;
    }
    }
.authentication_link > ul{
    display: flex;
    align-items: center;
    gap: 1em;
}
.authentication_link > ul > .link{
    font-size: .8rem;
    font-family: "Arimo", sans-serif !important;
}
.authentication_link > ul > .link > Button{
    border: none;
    outline: none;
    color: #000;
    cursor: pointer;
    padding: .7em;
}
@media (min-width: 1020px) {
    .authentication_link > ul > .link > Button{
        font-size: 1.3em;
        font-weight: 500;
    }
    .authentication_link > ul > .link > Button:hover{
        color: #20c997;
    }
    
}
.app_btn_header{
    background: #2d3132;
    color: #fff;
    padding: .8em;
    outline: none;
    border: none;
    border-radius: 5px;
    transition: all .35s ease-in;
    font-family: "Arimo", sans-serif !important;
    font-weight: 600;
    cursor: pointer;
    transition: all .4s ease-out;
    
}
@media (min-width: 1020px) {
    .app_btn_header{
        padding: 1em;
        font-size: 1em;
    }
}
.app_btn_header:hover,
.app_btn_header:focus {
  box-shadow: inset 0 -3.25em 0 0 #20c997;
  transition: all .4s ease-in;
}

/* MOBILE  */
@media (min-width: 700px) {
    .hamburger{
        display: none;
    }
}

.mobile_nav_list{
    margin-top: 1.5em;
}

.mobile_nav_list > ul > li{
   padding: 1em;
   font-weight: 600;
}

.link {
    display: flex;
    color: #000;
    width: 100%;
    justify-content: space-between;
    align-items: center;
} 
.mobile_link{
    display: flex;
    color: #000;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.mobile_link:hover{
    color: #20c997;
    fill: #20c997;
}

/* MOBILE DROPDOWN  */
.drop_why_mobile{
    position: relative;
    display: inline-block;
    width: 97%;
    /* margin-left: .5em; */
}
.drop_why_content_mobile{
    opacity: 0;
  visibility: hidden;
  position: absolute;
  /* left: -300px; */
  margin-top: 3em;
  /* margin-right: 2em; */
  transform: translate(0, 10px);
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: auto;
  border-radius: 5px;
}
.drop_why_content_mobile:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  }
.drop_why_mobile:hover .drop_why_content_mobile {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
  .drop_why_mobile > li{
    font-size: .9rem;
    font-weight: 600;
    margin-left: 1em;
  }

  /* first drop down content styles MOBILE  */
  .drop_why_content_mobile{
    padding: .8em;
  }
  .drop_section_mobile{
    display: flex;
    align-items: center;
    gap: 3em;
    padding-top: 1em;
  }

  .header_drop_mobile{
    font-weight: 700;
    font-size: .99rem;
    margin-bottom: .8em;

  }
  .drop_list_mobile{
    margin-bottom: .7em;
    cursor: pointer;
  }
  .drop_list_mobile:hover{
    color: #20c997;
  }
/* END  */

  /* LEARN DROPDOWN SECTION STARTS */
  .learn-drop_mobile{
    position: relative;
    display: inline-block;
    width: 93%;
    margin-left: .8em;
}
.drop_learn_content_mobile{
    opacity: 0;
  visibility: hidden;
  position: absolute;
  /* left: -300px; */
  margin-top: 3em;
  /* margin-right: 2em; */
  transform: translate(0, 10px);
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 90%;
  border-radius: 5px;
}
.drop_learn_content_mobile:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  }
.learn-drop_mobile:hover .drop_learn_content_mobile {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
  .learn-drop_mobile > li{
    font-size: .9rem;
    font-weight: 600;
    margin-top: 1.2em;
  }

  /* first drop down content styles MOBILE  */
  .drop_learn_content_mobile{
    padding: .8em;
  }
  .drop_learn_section_mobile{
    display: flex;
    align-items: center;
    gap: 3em;
    padding-top: .8em;
  }

  .learn-drop_mobile:hover .drop_learn_content_mobile {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
  .learn_list_container_mobile > li{
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: .8em;
  }
  .learn_list_container_mobile{
    margin-bottom: .8em;
    cursor: pointer;
  }
  .learn_list_container_mobilet:hover{
    color: #20c997;
  }
/* END  */


/* link */

.drop_list_link{
  color: #000;
  text-decoration: none;
}

 .active{
  color: #20c997;
}