.pay_with_ease{
    padding: .8em;
    /* border: 2px solid linear-gradient(to left, #0c4124, #23BE6E); */
    border: 2px solid;
    border-image: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%) 1;
    display: flex;
    align-items: center;
    font-size: .76rem;
    width: 150px;
    justify-content: space-around;
    margin-bottom: 1.8em;
    background: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}
@media (min-width: 1200px) {
    .pay_with_ease{
        font-size: 1rem;
        width: 190px;
        font-weight: 800;
    }
}

.spend_width_ease{
    padding: .8em;
    /* border: 2px solid linear-gradient(to left, #0c4124, #23BE6E); */
    border: 2px solid;
    border-image: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%) 1;
    display: flex;
    align-items: center;
    font-size: .76rem;
    width: 168px;
    justify-content: space-around;
    margin-bottom: 1.8em;
    background: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}
@media (min-width: 1200px) {
    .spend_width_ease{
        font-size: 1rem;
        width: 200px;
        font-weight: 800;
    }
}


.m_btn{
    padding: 1em;
    /* border: 2px solid linear-gradient(to left, #0c4124, #23BE6E); */
    
    border: 3.5px solid;
    border-image: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%) 1;
    display: flex;
    align-items: center;
    font-size: .8rem;
    width: 150px;
    justify-content: space-around;
    margin-bottom: 1.8em;
    background: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    border-radius: 6px;
}

.app_btn{
    color: #fff;
    font-family: "Sora", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    box-shadow: 0px 6px 40px rgb(90 66 248 / 40%);
    border-radius: 6px;
    transition: .5s;
    background: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%);
    padding: 1em;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    gap: 1em;
    width: 12em;
}

.images_android{
    width: 20px;
    height: 20px;
    filter: brightness(0) invert(1);
}

.download_images_android{
    width: 20px;
    height: 20px;
    filter: invert(19%) sepia(21%) saturate(1483%) hue-rotate(94deg) brightness(92%) contrast(95%);
}

.download_btn{
    /* color: #fff; */
    font-family: "Sora", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    line-height: 26px;
    /* box-shadow: 0px 6px 40px rgb(90 66 248 / 40%); */
    border-radius: 6px;
    transition: .5s;
    /* background: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%); */
    padding: 1em;
    /* border: none; */
    outline: none;
    display: flex;
    align-items: center;
    gap: 1em;
    width: 14em;
    background: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 2px solid;
}

.download_app_icon{
    fill: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%);
}
.normal_btn{
    color: #fff;
    font-family: "Sora", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    box-shadow: 0px 6px 40px rgb(90 66 248 / 40%);
    border-radius: 6px;
    transition: .5s;
    background: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%);
    padding: 1em;
    border: none;
    outline: none;
    width: 12em;
}


.sell_get_started_btn{
    display: inline-flex;
    align-items: center;
    color: #fff;
    font-family: "Sora", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    padding: .8em .92em;
    background: #23BE6E;
    border-radius: 32px;
    outline: none;
    border: none;
    cursor: pointer;
}

.sell_get_started_btn:hover,
.sell_get_started_btn:focus {
  box-shadow: inset 0 -3.25em 0 0 rgb(55, 55, 216);
  transition: all .4s ease-in;
}
@media (min-width: 1020px) {
    .sell_get_started_btn{
        padding: .8em 1.5em;
    }
}

.fintech_get_started_btn{
    display: inline-flex;
    align-items: center;
    color: #fff;
    font-family: "Sora", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    padding: .8em .92em;
    box-shadow: 0px 6px 40px rgb(90 66 248 / 40%);
    background: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%);
    border-radius: 32px;
    outline: none;
    border: none;
    cursor: pointer;
}
@media (min-width: 1020px) {
    .fintech_get_started_btn{
        padding: .8em 2em;
    }
}

.get_link{
    width: fit-content;
    height: fit-content;
}