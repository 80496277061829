.send_container{
    margin-top: 1.2em;
    font-family: "Sora", sans-serif;
    transition: all .4s ease-in;
}
/* @media (min-width: 1020px) {
    .send_container{
        border: 2px solid red;
        overflow: hidden;
    }
} */
.send_header{
    font-family: "Sora", sans-serif;
    line-height: 44px;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
}
@media (min-width: 1020px) {
    .send_header{
        font-size: 2.5rem;
        width: 50%;
        margin: auto;
    }
}

.send_paragraph{
    color: #6c6f70;
    font-family: "Sora", sans-serif;
    font-size: 1rem;
    width: 92%;
    margin: auto;
    line-height: 30px;
    margin-top: 1.2em;
    text-align: center;
}
@media (min-width: 1020px) {
    .send_paragraph{
        width: 62%;
    }
}

.send_boxes{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2em;
    margin-top:  2.7em;
    margin-left: .6em;
    
}
@media (min-width: 1000px) {
    .send_boxes{
        flex-wrap: nowrap;
        width: 90%;
        margin: 2.7em auto auto;
    }
}
.easy_to_use_box{
    /* width: 90%; */
    background: #fffaf1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;
    border-radius: 15px;
    cursor: pointer;

}
.easy_to_use_box:hover{
    cursor: pointer;
    transform: translateY(-.3rem);
    transition: all .2s ease-in;
}

.easy_to_use_box_content, .transaction_box_content, .transaction_box_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.img_div{
    width: 80px;
    height: 80px;
    border-radius: 6px;
    padding: 1em;
    margin: 3em auto auto;
    background: linear-gradient(146.57deg, #f9bb44 34.5%, #f08334 94.6%);
    box-shadow: 0px 6px 40px rgb(240 131 52 / 40%);
    border-radius: 6px;
}
.easy_image, .transaction_image{
    vertical-align: middle;
}

.ease_to_use_h{
    font-family: "Sora", sans-serif;
    font-size: 1.38rem;
    line-height: 34px;
    color: #2d3132;
    text-align: center;
    margin-top: 1.3em;
    margin-bottom: 1.1em;
}

.easy_to_use_paragraph{
    color: #6c6f70;
    font-family: "Sora", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 30px;
    text-align: center;
    width: 95%;
    margin-bottom: 4.8em;
}
@media (min-width: 1000px) {
    .easy_to_use_paragraph{
        margin-bottom: 5em;
    }
}

.transaction_box{
    background-color: rgb(248, 255, 240);
    visibility: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;
    border-radius: 15px;
    cursor: pointer;
}
.transaction_box:hover{
    cursor: pointer;
    transform: translateY(-.3rem);
    transition: all .2s ease-in;
}

.transaction_img_div{
    width: 80px;
    height: 80px;
    border-radius: 6px;
    padding: 1em;
    margin: 3em auto auto;
    background: linear-gradient(146.57deg, #9fe646 34.5%, #54c242 94.6%);
    box-shadow: 0px 6px 40px rgb(84 194 66 / 40%);
    border-radius: 6px;
}

.security_box{
    background-color: rgb(245, 253, 255);
    visibility: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;
    border-radius: 15px;
    cursor: pointer;
}
.security_box:hover{
    cursor: pointer;
    transform: translateY(-.3rem);
    transition: all .2s ease-in;
}

.security_img_div{
    width: 80px;
    height: 80px;
    border-radius: 6px;
    padding: 1em;
    margin: 3em auto auto;
    background: linear-gradient(146.57deg, #39bde7 34.5%, #4b87e0 94.6%);
    box-shadow: 0px 6px 40px rgb(68 127 215 / 40%);
    border-radius: 6px;
}

.send_money_app_section{
    margin-top: 6em;
}
@media (min-width: 700px) {
    .send_app_section_content{
        display: flex;
        align-items: center;
        /* width: 90%; */
        /* margin: auto; */
        /* justify-content: space-around; */
    }
    
}
@media (min-width: 1020px) {
    .send_app_section_content{
        width: 90%;
        margin: auto;
        gap: 4em;
    }
    
}
.phone_app_img{
    width: 95%;
}
@media (min-width: 450px) {
    .phone_app_img{
        width: 22rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }
}
@media (min-width: 700px) {
    .phone_app_img{
        width: 30%;
        /* margin: 1em ; */
    }
}
.send_easy_text_h2{
    font-size: 2rem;
    width: 80%;
    line-height: 44px;
    margin-bottom: 1em;
    /* text-align: center; */
    margin: auto;
    font-family: "Sora", sans-serif;
    font-weight: 600;
    margin-top: 2em;
}
@media (min-width: 700px) {
    .send_easy_text_h2{
        font-size: 1.8rem;
        width: 60%;
    }
}

@media (min-width: 1000px) {
    .send_easy_text_h2{
        font-size: 2.6rem;
        width: 100%;
        line-height: 53px;
    }
}


.send_easy_text_p{
    color: #6c6f70;
    font-family: "Sora", sans-serif;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    margin: auto;
    margin-top: 1.4em;
    width: 81%;
}

@media (min-width: 700px) {
    .send_easy_text_p{
        width: 60%;
    }
}
@media (min-width: 1000px) {
    .send_easy_text_p{
        width: 100%;
    }
}

.ease_offers_list{
    width: 95%;
    margin: 2em;
}
@media (min-width: 600px) {
    .ease_offers_list{
        margin-left: 5.5em;
    }
}
@media (min-width: 1000px) {
    .ease_offers_list{
        margin: 2em auto auto;
        width: 100%;
    }
}

.mark_text{
    display: flex;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;
}
.thick_container{
    font-size: 14px;
    width: 26px;
    height: 26px;
    background: #66cb44;
    color: #fff;
    border-radius: 50%;
    padding: .1em .3em .5em .5em;
}

.ease_list{
    font-family: "Sora", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    line-height: 30px;
    color: #2d3132;
    width: 80%;
}

.comming_soon_btn_div{
    margin-top: 3em;
}