.brand_top{
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 1em;
}
@media (min-width: 1020px) {
    .brand_top{
        justify-content: space-between;
        flex-wrap: nowrap;
        margin: 2em;
    }
}
.brand_top_h1{
    font-size: 2.3rem;
    line-height: 1.1;
    color: #050505;
    margin-bottom: 2.2rem;
    width: 100%;
    font-family: "Sora", sans-serif;
}
@media (min-width: 1020px) {
    .brand_top_h1{
        font-weight: 700;
        font-size: 55px;
        width: 80%;
    }
}
.brand_top_h1 > span{
    color: #082b18;
}
.brand_top_p{
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #5e6282;
    font-family: "DM Sans", sans-serif;
    width: 95%;
}
@media (min-width: 1020px) {
    .brand_top_p{
        width: 58%;
    }
}
.world_img{
    width: 80%;
}
@media (min-width: 1020px) {
    .world_img{
        width: 50%;
    }
}

/* CUSTOMER T */
.customer_section{
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 1em;
}
@media (min-width: 1020px) {
    .customer_section{
        justify-content: space-between;
        flex-wrap: nowrap;
    }
}
.customer_img{
    width: 90%;
}
@media (min-width: 1020px) {
    .customer_img{
        width: 80%;
    }
}

.customer_header{
    font-size: 1.85rem;
    line-height: 46px;
    font-weight: 600;
    color: #050505;
    font-family: "Sora", sans-serif;
    margin-bottom: .9em;
    width: 95%;
}
@media (min-width: 1020px) {
    .customer_header{
        font-weight: 700;
        font-size: 55px;
        width: 95%;
    }
}
.customer_header > span{
    position: relative;
}
.customer_header > span::after{
    position: absolute;
    content: "";
    width: 60%;
    height: 60%;
    background-image: url(../../../assets/text_bordr.png);
    background-repeat: no-repeat;
    background-position: center;
    left: .5em;
    z-index: -1;
    top: 2px;
}
.customer_p{
    font-size: 16px;
    line-height: 28px;
    font-family: "DM Sans", sans-serif;
    color: #5e6282;
}

.get_started_customer_btn_content{
    display: flex;
    align-items: center;
    gap: 1em;
}
@media (min-width: 1020px) {
    .get_started_customer-btn_container{
        margin-top: 1.4em;
    }
}
@media (min-width: 1020px) {
    .get_started_customer_btn_content{
        font-size: 1.5rem;
    }
}
.arrow_right{
    animation: slide1 1s ease-in-out infinite;
    margin-left: 9px;
}
/* CHECKOUT SECTIONS */
.checkout_div_main{
    margin: auto;
}

.customer_tt{
    margin: 1em;
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 1em;
    margin-top: 3em;
}
@media (min-width: 1020px) {
    .customer_tt{
        justify-content: space-between;
        flex-wrap: nowrap;
        margin: 2em;
        margin-top: 4.5em;
    }
}


.checkout_{
    position: relative;
}
.checkout_::after{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url(../../../assets/text_bottom_line.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    left: .5em;
    top: 2px;
}
.checkout_image{
    width: 350px;
    height: 550px;
    margin-bottom: 3em;
    margin-top: 2em;
}
@media (min-width: 1020px) {
    .checkout_image{
        width: 600px;
        /* height: 400px; */
    }
}
@media (min-width: 1020px) {
    .br{
        display: none;
    }
}
@media (min-width: 1020px) {
    .checkout_header{
        width: 70%;
    }
}
@media (min-width: 1020px) {
    .checkout_p{
        width: 68%;
    }
}

/* KEYFRAME */

@keyframes slide1 {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(10px, 0);
    }
  }