.equip_api_div{
    margin-top: 2em;
}
@media (min-width: 1020px) {
    .equip_api_div{
        margin-top: 6.5em;
    }
}

.equip_api_h2{
    font-size: 1.5rem;
    line-height: 32px;
    color: #0f0d0d;
    font-family: "Sora", serif;
    font-weight: 700;
    /* margin-top: 20px; */
    text-align: center;
    width: 85%;
    margin: auto;
}
@media (min-width: 1020px) {
    .equip_api_h2{
        font-size: 2.7rem;
        width: 50%;
        line-height: 48px;
    }
}

@media (min-width: 900px) {
    .equip_api_box_div{
        display: flex;
        align-items: center;
        gap: 2em;
    }
}
@media (min-width: 1020px) {
    .equip_api_box_div{
        gap: 1.2em;
        justify-content: center;
        width: 100%;
    }
}



.api_box{
    margin: 3em auto auto;
    width: 90%;
    padding: 1em 1.5em 2em;
    border-radius: 25px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}

.api_box:hover{
    cursor: pointer;
    transform: translateY(-.3rem);
    transition: all .2s ease-in;
}
@media (min-width: 1020px) {
    .api_box{
        width: 23%;
        margin: 3em 0 0;
    }
}

.api_h5{
    color: #0F0D0D;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 28px;
    margin-top: 40px; 
    text-align: center;
}

@media (min-width: 1020px) {
    .api_h5{
        margin-top: 3.5em;
        margin-bottom: 1em;
    }
}

.api_p{
    color: #565454;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 28px;
    padding-top: 10px;
    text-align: left;
    margin: auto;
    width: 90%;
}

@media (min-width: 1020px) {
    .api_p{
        margin-bottom: 1.5em;
    }
}