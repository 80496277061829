@media (min-width: 1020px) {
    .reporting_content{
        display: flex;
        margin: 4em auto auto;
        justify-content: center;
        width: 90%;
        align-items: center;
    }
}
.reporting_images{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6em;
    border-radius: 20px;
    position: relative;
}


.commerce_image_reconciliation{
    width: 22rem;
    border-radius: 20px;
}
@media (min-width: 600px) {
    .commerce_image_reconciliation{
        width: 25rem;
    }
}
@media (min-width: 1020px) {
    .commerce_image_reconciliation{
        width: 36rem;
    }
}

.reporting_t{
    margin: 2em;
}
.reporting_h2{
    font-size: 28px;
    line-height: 48px;
    color: #0f0d0d;
    font-family: "Sora", serif;
    font-weight: 700;
    margin-top: 20px;
}
@media (min-width: 600px) {
    .reporting_h2{
        width: 80%;
        margin: 1.6em auto auto;
    }
}
@media (min-width: 900px) {
    .reporting_h2{
        text-align: center;
    }
}
@media (min-width: 1020px) {
    .reporting_h2{
        text-align: left;
        width: 90%;
        font-size: 3rem;
        line-height: 60px;
    }
}


.reporting_p{
    margin-top: 25px;
    color: #565454;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 32px;
    /* width: 90%; */
}

@media (min-width: 600px) {
    .reporting_p{
        width: 80%;
        margin: 1em auto auto;
    }
}
@media (min-width: 900px) {
    .reporting_p{
        text-align: center;
    }
}
@media (min-width: 1020px) {
    .reporting_p{
        text-align: left;
        width: 90%;
        font-size: 1.2rem;
    }
}
