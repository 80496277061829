@media (min-width: 1020px) {
    .payment_div_content{
        display: flex;
        margin: 6em auto auto;
        justify-content: center;
        width: 90%;
        align-items: center;
    }
}
.payment_images_collect{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6em;
    border-radius: 20px;
    position: relative;
}
.line_star{
    position: absolute;
    right: 0;
    top: -2em;
}
@media (min-width: 480px) {
    .line_star{
        display: none;
    }
}
@media (min-width: 1020px) {
    .line_star{
        display: block;
        right: -2em;
        bottom: 26em;
    }
}

.payment_image_collect{
    width: 22rem;
    border-radius: 20px;
}
@media (min-width: 600px) {
    .payment_image_collect{
        width: 25rem;
    }
}
@media (min-width: 1020px) {
    .payment_image_collect{
        width: 36rem;
    }
}
.payment_collect_ease_h1{
    font-size: 38px;
    line-height: 48px;
    color: #262B34;
    font-family: "Sora", sans-serif;
    font-weight: 700;
    margin-top: 20px;
    text-align: center;
}
@media (min-width: 600px) {
    .payment_collect_ease_h1{
        width: 80%;
        text-align: center;
        margin: 1.6em auto auto;
    }
}
@media (min-width: 1020px) {
    .payment_collect_ease_h1{
        text-align: left;
        width: 60%;
        font-size: 40px;
    }
}

.payment_collect_ease_span{
    background: linear-gradient(88.24deg, #64e4dd 19.97%, #6fa0fe 44.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.payment_collect_ease_p{
    margin-top: 25px;
    color: #606B7E;
    font-family: "Sora", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
}

@media (min-width: 600px) {
    .payment_collect_ease_p{
        width: 80%;
        margin: 1em auto auto;
    }
}
@media (min-width: 1020px) {
    .payment_collect_ease_p{
        text-align: left;
        width: 60%;
    }
}

.payment_collect_ease_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
}
@media (min-width: 1020px) {
    .payment_collect_ease_btn{
        display: block;
        width: 80%;
        margin-left: 8em;
    }
}