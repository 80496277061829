.meet_our_team_h{
    font-size: 2.4rem;
    font-weight: 800;
    font-family: "Manrope", sans-serif;
    color: #2c264a;
    text-align: center;
    margin-top: 1.2em;
}

.meet_our_team_p{
    font-size: 1rem;
    line-height: 28px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    color: #4c4c63;
    margin-top: 1em;
    text-align: center;
    width: 98%;
}

@media (min-width: 900px) {
    .meet_our_team_p{
        width: 70%;
        margin:  1.2em auto 1em;
    }
}
@media (min-width: 1020px) {
    .meet_our_team_p{
        width: 40%;
    }
}

.our_team_images{
    margin: 2em .8em 1em;
}
@media (min-width: 600px) {
    .our_team_images{
        /* margin: 2em 2em 1em; */
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
@media (min-width: 1020px) {
    .our_team_images{
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-left: 5em;
        gap: 2.5em;
    }
}
.luckyy_image, .obinna_image{
    border-radius: 10px;
    margin-bottom: 1.2em;
    margin-top: 1em;
    filter: grayscale(1);
    cursor: pointer;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    transition: all .3s ease;
    width: 95%;
    margin-left: .5em;
}
@media (min-width: 1020px) {
    .luckyy_image, .obinna_image{
        width: 90%;
        position: relative;
    }
}
.luckyy_image:hover{
    filter: none;
    transform: scale(1.05);
}
.obinna_image:hover{
    filter: none;
    transform: scale(1.05);
}



.obinna_image{
    margin-top: 3em;
}
@media (min-width: 1020px) {
    .obinna_image{
        margin: auto;
        bottom: .1em;
    }
}


.ceo_name{
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
    color: #2c264a;
    transition: color 0.3s;
    font-family: "Manrope", sans-serif;
}

.position{
    font-size: .8rem;
    line-height: 28px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    color: #4c4c63;
}
@media (min-width: 1020px) {
    .obi{
        position: relative;
        top: .8em;
    }
    .obi1{
        position: relative;
        top: 1.1em;
    }
}