.recieve_payment_container{
    margin-top: 7em;
}
@media (min-width: 700px) {
    .recieve_payment_container{
        display: flex;
        align-items: flex-start;
        width: 100%;
        margin: 7em 1em 1em;
    }
}
@media (min-width: 1020px) {
    .recieve_payment_container{
        justify-content: center;
        margin: 7em auto auto;
    }
}
.recieve_text_btn_container{
    width: 85%;
    margin: auto;
}
@media (min-width: 1020px) {
    .recieve_text_btn_container{
        margin-left: 2.5em;
    }
}

.payment_h2{
    font-size: 2rem;
    line-height: 44px;
    font-family: "Sora", sans-serif;
    font-weight: 600;
    margin-bottom: 1em;
}
@media (min-width: 1020px) {
    .payment_h2{
        width: 65%;
        font-size: 2.4rem;
    }
}

.recieve_paragraph{
    color: #6c6f70;
    font-family: "Sora", sans-serif;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 1.5em;
}
@media (min-width: 1020px) {
    .recieve_paragraph{
        width: 80%;
    }
}

.money_recievd_image{
    width: 90%;
    /* margin-left: 1em; */
    margin: 4em auto auto .5em;
    /* margin-top: 4em; */
}
@media (min-width: 700px) {
    .money_recievd_image{
        width: 50%;
    }
}
@media (min-width: 1020px) {
    .money_recievd_image{
        width: 30%;
        margin: auto 2.5em auto auto;
    }
}

.download_btn_user{
    display: flex;
    flex-wrap: wrap;
    gap: 1.3em;
    width: 90%;
}