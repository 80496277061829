.custom_container{
    /* width: 95%; */

}
.custom_content{
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2em;
    align-items: center;
}
@media (min-width: 1020px) {
    .custom_content{
        flex-wrap: nowrap;
        justify-content: space-between;
    }
}

.custom_t{
    margin: 2em;
}

.custom_h2{
    font-size: 2rem;
    line-height: 46px;
    color: #050505;
    font-family: "Sora", sans-serif;
    margin-bottom: 15px;    
    font-weight: 600;
    /* width: 90%; */
}
@media (min-width: 900px) {
    .custom_h2{
        width: 90%;
        text-align: center;
    }
}
@media (min-width: 1020px) {
    .custom_h2{
        text-align: left;
        width: 100%;
        font-size: 2.5rem;
    }
}
.custom_p{
    color: #565454;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    width: 90%;
}
@media (min-width: 900px) {
    .custom_p{
        width: 90%;
        text-align: center;
    }
}
@media (min-width: 1020px) {
    .custom_p{
        width: 100%;
        text-align: left;
    }
}


@media (min-width: 900px) {
    .custom_btn{
        margin: .8em auto auto 22em;
    }
}
@media (min-width: 1020px) {
    .custom_btn{
        margin: 1em auto auto;
    }
}

.custom_image_container{
    position: relative;
    width: 100%;
    margin: 1em;
}
@media (min-width: 600px) {
    .custom_image_container{
        margin-left: 6em;
    }
}

@media (min-width: 900px) {
    .custom_image_container{
        margin-left: 15em;
    }
}

@media (min-width: 1020px) {
    .custom_image_container{
        margin: 0;
    }
}
.custom_floating_image{
    position: absolute;
    z-index: -1;
}
.floating_icon{
    width: 60px;

}
.custom_bold_image{
    z-index: 1;
}
.custom_img{
    width: 380px;
}

@media (min-width: 900px) {
    .custom_img{
        width: 520px;

    }
}

.react_icon{
    animation-name: react_icon;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    position: relative;
    bottom: 2em;
    right: 1.5em;
}
@keyframes react_icon {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 12px); }
    to   { 
        transform: translate(0, -0px);
     }    
}

.php_icon{
    position: relative;
    top: 8em;
    animation-name: php;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    width: 55px;
}
@keyframes php {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 15px); }
    to   { 
        transform: translate(0, -0px);
     }    
}

.node_icon{
    position: relative;
    top: 3em;
    animation-name: node;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
}
@keyframes node {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 18px); }
    to   { 
            transform: translate(0, -0px);
            animation-timing-function: ease;
     }    
}

.youtube_icon{
    position: relative;
    top: 7em;
    right: 4.5em;
    width: 70px !important;
    animation-name: youtube;
    animation-duration: 5.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
}

@keyframes youtube {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 25px); }
    to   { 
            transform: translate(0, -0px);
            animation-timing-function: ease-out;
     }    
}


.python_icon{
    position: relative;
    left: 12em;
    top: 4.5em;
    width: 50px;
    animation-name: python_icon;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
}

@keyframes python_icon {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 20px); }
    to   { 
            transform: translate(0, -0px);
            animation-timing-function: ease-out;
     }    
}


.code_icon{
    position: relative;
    left: 4.5em;
    top: 13em;
    animation-name: code_icon;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
}
@keyframes code_icon {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 20px); }
    to   { 
            transform: translate(0, -0px);
            animation-timing-function: ease-out;
     }    
}
  