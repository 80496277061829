.footer_container{
    margin-top: 12em;
}
.get_started_container{
        position: relative;
}
.get_started_content{
    background: url("../../../assets/scta_bg.png");
    padding: 1em 1em;
    position: absolute;
    width: 90%;
    left: 1.2em;
    top: -8em;
    border-radius: 6px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;

}

@media (min-width: 700px) {
    .get_started_content{
        left: 2em;
    }
}
@media (min-width: 1020px) {
    .get_started_content{
        left: 3.5em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 58px 45px;
    }
}

.address_contant_link_container{
     background-color: rgb(8, 43, 24);
     width: 100%;
}
@media (min-width: 900px) {
    .main_info_list{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 90%;
        justify-content: space-between;
        gap: 6em;
        margin: auto;
    }
}
@media (min-width: 1020px) {
    .main_info_list{
        width: 80%;
    }
}
.get_started_h{
    color: #fff;
    font-family: "Sora", sans-serif;
    font-weight: 600;
    font-size: 30px;
    margin: .7em;
}

.get_started_p{
    color: #dec2fb;
    font-family: "Sora", sans-serif;
    font-weight: 400;
    margin: 1em;
}
@media (min-width: 1020px) {
    .get_started_p{
        font-size: .9rem;
    }
}

.footer_create_account_btn{
    border: none;
    outline: none;
    display: inline-block;
    color: #5044f9;
    font-family: "Sora", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    background: #fff;
    border-radius: 6px;
    padding: .7em 3em;
    margin: 1em;
}

.address_contant_link_container{
    padding-top: 13em;
    padding-left: 1.5em;
    position: relative;
}

.logo_icon{

    margin-top: 1.5em;
    margin-bottom: 1.2em;
    position: relative;
}
.address_list{
    position: relative;
}

.ad{
    color: #fff;
    margin-top: 1em;
    display: flex;
    align-items: center;
    gap: .5em;
}
@media (min-width: 1020px) {
    .ad{
        font-size: .9rem;
    }
}
@media (min-width: 900px) {
    .learn{
        margin-right: 5em;
    }
}
.learn_h{
    font-family: "Sora", sans-serif;
    color: #fff;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 24px;
    margin-bottom: .8em;
    margin-top: 2em;
}
@media (min-width: 1020px) {
    .learn_h{
        font-size: 1.5rem;
    }
}
.us, .why_sabi_footer_li{
    font-family: "Sora", sans-serif;
    color: #b8babe;
    font-size: 1rem;
    line-height: 30px;
    cursor: pointer;
}

.why_sabi_footer_li:hover{
    color: #fff;
}
.us:hover{
    color: #fff;
}

@media (min-width: 1020px) {
    .us, .why_sabi_footer_li{
        font-size: .9rem;
    }
}

.whysabipay_footer_h{
    font-family: "Sora", sans-serif;
    color: #fff;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 24px;
    margin-bottom: .8em;
    margin-top: 2em;
}
@media (min-width: 1020px) {
    .whysabipay_footer_h{
        font-size: 1.5rem;
    }
}


.line{
    border: .2px solid rgb(118, 110, 110);
    width: 90%;
}
@media (min-width: 900px) {
    .line{
        margin-top: 2em;
    }
}
@media (min-width: 1020px) {
    .line{
        margin-top: 5em;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 700px) {
    .copy_right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
    }
}
@media (min-width: 1020px) {
    .copy_right{
        margin-top: 2em;
    }
}
.copy_right_p{
    color: #b8babe;
    font-family: "Sora", sans-serif;
    font-size: 16px;
    margin: 1.2em 1em 1em;
}

.icons{
    display: flex;
    align-items: center;
    gap: 1em;
    margin-left: 2em;
    padding-top: 1em;
}

.icon{
    font-size: 22px;
    background: rgba(255, 255, 255, 0.1);
    padding: 10px 12px 4px;
    border-radius: 50px;
    /* margin: auto; */
    margin-bottom: 2.6em;
}