.newsletter_image{
    background-image: url("../../../../assets/subscrib.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}
@media (min-width: 1020px) {
    .newsletter_image{
        /* margin-top: 6em; */
        margin-bottom: 0;
        position: relative;
        top: 4em;
        height: 60vh;
    }
}
.newsletter_h5{
    font-size: 16px;
    font-weight: 400;
    color: #4c4c63;
    font-family: "Manrope", sans-serif;
    text-align: center;
    padding-top: 6em;
}
.newsletter_h2{
    font-size: 38px;
    margin-bottom: 15px;
    font-weight: 800;
    font-family: "Manrope", sans-serif;
    color: #2c264a;
    text-align: center;
}
@media (min-width: 900px) {
    .news_letter_form{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.input_div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin-top: 1em;
}
@media (min-width: 900px) {
    .input_div{
        flex-direction: row;
    }
}
.input{
    padding: 1.5em;
    width: 90%;
    z-index: 1;
    border: none;
    outline: none;
    margin: auto;
    border-radius: 5px;
    color: #2c264a;
}
@media (min-width: 900px) {
    .input{
        width: 95%;
    }
}

.subscribe_btn{
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background: #23BE6E;
    border: 0px;
    border-radius: 5px;
    padding: 20px 35px;
    width: 100%;
    cursor: pointer;
    width: 90%;
    margin: 1.15em;
}
.subscribe_btn:hover,
.subscribe_btn:focus {
  box-shadow: inset 0 -4.25em 0 0 #0d6efd;
  transition: all .4s ease-in;
}
@media (min-width: 900px) {
    .subscribe_btn{
        width: 18%;
        margin-top: 2.2em;
    }
}

.span_message{
    font-size: 16px;
    line-height: 28px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    color: #4c4c63;
    text-align: center;
    padding-bottom: 1em;
}