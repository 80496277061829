.sell_top_button_div{
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    position: relative;
    width: 13em;
    margin: auto;
}

.lines{
    position: absolute;
    right: -1.5em;
    bottom: 2em;
}
.button_div{
    border: 1px solid #f1f1f2;
    /* width: 13rem; */
    padding: .7em;
    border-radius: 25px;
}

.sell_h1{
    font-size: 38px;
    line-height: 48px;
    color: #262B34;
    font-family: "Sora", sans-serif;
    font-weight: 700;
    margin-top: 20px;
    text-align: center;
}

.sell_span{
    background: linear-gradient(267.73deg, #D04943 61.18%, #666BE3 81.47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.sell_p{
    margin-top: 25px;
    color: #606B7E;
    font-family: "Sora", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
}
@media (min-width: 700px) {
    .sell_p{
        width: 90%;
        margin: 25px auto auto;
    }
}

.sell_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
}
