@media (min-width: 600px) {
    .main{
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
    }
}
@media (min-width: 900px) {
    .main{
        display: flex;
        /* flex-direction: column; */
        justify-content: safe;
    }
}
.customer_payment_image{
    margin: 1em;
}
@media (min-width: 900px) {
    .customer_payment_image{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3em;
        width: 100%;
    }
}
@media (min-width: 1020px) {
    .customer_payment_image{
        gap: 5em;
    }
}

.pay_with_ease{
    padding: .8em;
    /* border: 2px solid linear-gradient(to left, #0c4124, #23BE6E); */
    border: 2px solid;
    border-image: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%) 1;
    display: flex;
    align-items: center;
    font-size: .76rem;
    width: 150px;
    justify-content: space-around;
    margin-bottom: 1.8em;
    background: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}
@media (min-width: 1200px) {
    .pay_with_ease{
        font-size: 1rem;
        width: 190px;
        font-weight: 800;
    }
}
.payment_header{
    font-family: "Sora", sans-serif;
    color: #050505;
    line-height: 46px;
    font-weight: 600;
    font-size: 2.1rem;
    width: 350px;
    margin-bottom: .8em;
}
@media (min-width: 1200px) {
    .payment_header{
        font-size: 2.8rem;
        width: 500px;
        line-height: 52px;
    }
}
.main_paragraph{
    font-family: "Sora", sans-serif;
    color: #6c6f70;
    font-size: 16px;
    width: 350px;
    line-height: 28px;
    margin-bottom: 1.2em;
}
@media (min-width: 1200px) {
    .main_paragraph{
        font-size: 1.1rem;
        width: 530px;
    }
}
.get_started_btn{
    color: #fff;
    font-family: "Sora", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    box-shadow: 0px 6px 40px rgb(90 66 248 / 40%);
    border-radius: 6px;
    transition: .5s;
    background: linear-gradient(279.49deg, #0c4124 13.52%, #23BE6E 74.26%);
    padding: 20px 40px;
    border: none;
    outline: none;
}
.checkout_image{
    width: 600px;
    /* height: 550px; */
    margin-bottom: 3em;
    margin-top: 2em;
}
@media (min-width: 900px) {
    .checkout_image{
        width: 450px;
    }
}

@media (min-width: 1020px) {
    .checkout_image{
        height: 870px !important;
        visibility: visible;
        /* height: 580px;  */
        width: auto;
    }
}
